import React from 'react';
import GenericCover from '../components/covers/genericCover';
import EmpresaBig from '../components/covers/empresaBig';
import EmpresaLittle from '../components/covers/empresaLittle';
import InstalacaoBig from '../components/covers/instalacaoBig';
import InstalacaoLittle from '../components/covers/instalacaoLittle';
import ManutencaoBig from '../components/covers/manutencaoBig';
import ManutencaoLittle from '../components/covers/manutencaoLittle';
import ReparacaoBig from '../components/covers/reparacaoBig';
import ReparacaoLittle from '../components/covers/reparacaoLittle';
import AssistenciaBig from '../components/covers/assistenciaBig';
import AssistenciaLittle from '../components/covers/assistenciaLittle';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useLocation } from '@reach/router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';

const useStyles = makeStyles((theme) => ({
    gatsbyImage: {
      position: 'absolute',
      top: 80,
      left: 80
    }
}));
        
 const MdxCoverLayout = ({children}) => {
  const classes = useStyles();
  const location = useLocation();
  const lessThen500 = useMediaQuery(
    json2mq({
      maxWidth: 500,
    }),
  );
  const data = useStaticQuery(graphql`
    query {
      logochega: file(relativePath: { eq: "site-logo2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 116, placeholder: NONE)
        }
      }
    }
  `)

  const renderSwitch = () => {
    var myLocal = location.pathname;
    if (myLocal.slice(-1) === '/') {
      myLocal = location.pathname.slice(0, -1);
    }

    switch(myLocal) {
      case '/empresa':
        return (lessThen500 ? <EmpresaLittle/> : <EmpresaBig/>)
      case '/instalacao':
        return (lessThen500 ? <InstalacaoLittle/> : <InstalacaoBig/>)
      case '/manutencao':
        return (lessThen500 ? <ManutencaoLittle/> : <ManutencaoBig/>)
      case '/reparacao':
        return (lessThen500 ? <ReparacaoLittle/> : <ReparacaoBig/>)
      case '/assistencia':
        return (lessThen500 ? <AssistenciaLittle/> : <AssistenciaBig/>)
      default:
        return <GenericCover/>; 
    }
  }

    return(
        <div style={{ background: 'black'}}>
            {renderSwitch()}
            <div className={classes.gatsbyImage}>
                <a href="/">
                    <GatsbyImage alt="" image={data.logochega.childImageSharp.gatsbyImageData} />
                </a>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
}

export default MdxCoverLayout