import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"

const InstalacaoLittle = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        hapois: file(relativePath: { eq: "instalacao-coverLittle.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
      <GatsbyImage
        style={{
          gridArea: "1/1",
          height: 415
        }}
        layout="fullWidth"
        placeholder="blurred"
        aspectatio={3 / 1}
        alt="Cover"
        imgStyle={{height: 'initial', minHeight: 415}}
        image={data.hapois.childImageSharp.gatsbyImageData}
        formats={["auto", "webp", "avif"]}
      />
  )
}


export default InstalacaoLittle